<template>
	<div style="background-color: #fff;margin: 10px;border-radius: 3px;">
		
		
		<div style="padding-top: 15px;display: flex;align-items: center;background-color: #fff;justify-content: space-between;">
		
			<div style="margin-right: 20px;margin-left: 35px;">
				<el-button @click="showAddOrEditTypePop" type="primary" round icon="el-icon-plus" size="mini">添加分类</el-button>
			</div>
		</div>

		<!--表格start :height="tableHeight+'px'"-->

		<el-table id="mTable" :cell-style="{ 'text-align': 'center'}" :row-style="{ 'text-align': 'center'}" :height="tableHeight" :data="typeArr" style="width: 100%">
			<el-table-column type="expand">

				<template slot-scope="props">
					<div style="padding: 20px;padding-left: 50px;background-color: #F5F7FA;margin-top: -10px;display: flex;width: 100%;">

						<div class="dis" style="flex: 1;text-align: center;"><span style="color:rgb(136, 136, 136);font-size: 12px;">id</span></div>
						<div class="dis" style="flex: 1;text-align: center;"><span style="color:rgb(136, 136, 136);font-size: 12px;">名称</span></div>
						<div class="dis" style="flex: 1;text-align: center;"><span style="color:rgb(136, 136, 136);font-size: 12px;">图片</span></div>
						<!-- <div class="dis" style="flex: 1;text-align: center;"><span style="color:rgb(136, 136, 136);font-size: 12px;">排序</span></div>
						<div class="dis" style="flex: 1;text-align: center;"><span style="color:rgb(136, 136, 136);font-size: 12px;">创建时间</span></div>
						<div class="dis" style="flex: 1;text-align: center;"><span style="color:rgb(136, 136, 136);font-size: 12px;">更新时间</span></div> -->
						<!-- <div class="dis" style="flex: 1;text-align: center;"><span style="color:rgb(136, 136, 136);font-size: 12px;">操作</span></div> -->
					</div>
					<div v-if="props.row.category2List.length==0" style="background-color: #F5F7FA;text-align: center;padding: 30px;margin-top: -10px;color:rgb(136, 136, 136);font-size: 12px;">暂无数据</div>
					<div v-for="childerItem in props.row.category2List" :key='childerItem.id'>

						<div style="padding: 20px;padding-left: 50px;background-color: #F5F7FA;margin-top: -10px;display: flex;width: 100%;border-top: 1px solid #eee;">

							<div class="dis" style="flex: 1;text-align: center;"> <span style="font-size: 13px;color: #333;"> {{childerItem.id}}</span></div>
							<div class="dis" style="flex: 1;text-align: center;"> <span style="font-size: 13px;color: #333;">{{childerItem.name}}</span></div>
							<div class="dis" style="flex: 1;text-align: center;"><img :src="childerItem.img" style="width: 50px;height: 50px;cursor: pointer;border-radius: 5px;" @click="handlePreView(childerItem.img)" /> </div>
							<!-- <div class="dis" style="flex: 1;text-align: center;"> <span style="font-size: 13px;color: #333;">{{childerItem.sort}}</span></div>
							<div class="dis" style="flex: 1;text-align: center;"> <span style="font-size: 13px;color: #333;">{{childerItem.created_at}}</span></div>
							<div class="dis" style="flex: 1;text-align: center;"> <span style="font-size: 13px;color: #333;">{{childerItem.updated_at}}</span></div> -->


							<!--
							<div class="dis" style="flex: 1;text-align: center;">
								<el-dropdown trigger="hover" style="width: 80px;">
									<el-button size="mini" round type="primary">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</el-button>
									<el-dropdown-menu slot="dropdown">


									<- 	<el-dropdown-item>
											<el-button type="success" size="mini" round @click="showType2EditGoods(childerItem)">编辑
											</el-button>
										</el-dropdown-item> ->

										<el-dropdown-item>
											<el-button type="danger" size="mini" round @click="type2Del(childerItem)">删除
											</el-button>
										</el-dropdown-item>


									</el-dropdown-menu>
								</el-dropdown>
							</div>
							-->
							
							
						</div>

					</div>
				</template>

			</el-table-column>
			<el-table-column label="ID" prop="id"></el-table-column>
			<el-table-column label="名称" prop="name"></el-table-column>
<!-- 			<el-table-column label="排序" prop="sort"></el-table-column>
			<el-table-column label="创建时间" prop="created_at"></el-table-column>
			<el-table-column label="更新时间" prop="updated_at"></el-table-column>
 -->

			<!--
			<el-table-column label="操作">
				<template slot-scope="{ row, index }">
					<el-dropdown trigger="hover" style="width: 80px;">
						<el-button size="mini" round type="primary">
							操作<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">


							<!- <el-dropdown-item>
								<el-button type="success" size="mini" round @click="showType1EditGoods(row)">编辑
								</el-button>
							</el-dropdown-item> ->
							
							<el-dropdown-item>
								<el-button type="danger" size="mini" round @click="type1Del(row)">删除
								</el-button>
							</el-dropdown-item>


						</el-dropdown-menu>
					</el-dropdown>

				</template>
			</el-table-column>
			-->
			
			
		</el-table>
		<!--表格end-->

		<!--分页start-->
		<!-- <el-row type="flex" justify="end" align="middle" class="page" style="margin-top: 10px;margin-right: 50px;padding-bottom: 10px;">
			<el-pagination background layout="prev, pager, next" :current-page="pageNumber" :page-size="pageSize" :total="total" @current-change="changePage"></el-pagination>
			<span style="font-size: 13px;color: #999;">共 {{total}} 条</span>
		</el-row> -->
		<!--表格end-->

		<!--浏览图片的弹窗start-->
		<el-dialog width="400px" footer-hide title="预览" :visible.sync="isShowPreImg">
			<img :src="preImgUrl" style="width: 100%">
		</el-dialog>
		<!--浏览图片的弹窗end-->



		<!-- 添加或编辑一级分类弹窗start -->
		<el-dialog width="600px" custom-class="m-dialog" title="一级分类" center :visible.sync="isShowAddType1Pop">
			<div v-if="isShowAddType1Pop">
				<div style="padding-top: 20px;">

					<el-form style="margin-left: 80px;" label-width="100px" size="small" ref="type1Form" :rules="type1FormRules" :model="type1Form">


						<el-form-item label="名称" prop="name">
							<el-input style="width: 250px;" v-model="type1Form.name" placeholder="请输入名称"></el-input>
						</el-form-item>

						

						<!-- <el-form-item label="排序" prop="sort">
							<el-input min="0" max="100" style="width: 250px;" type="number" v-model="type1Form.sort" placeholder="请输入0-100的排序值"></el-input>
						</el-form-item> -->

					</el-form>

				</div>
				<div class="dis" style="justify-content: flex-end;">

					<el-button round style="margin-left: 10px" @click="type1Save('type1Form')" type="primary" size="small">保存</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 添加或编辑一级分类弹窗end -->



		<!-- 添加或编辑二级分类弹窗start -->
		<el-dialog width="600px" custom-class="m-dialog" title="二级分类" center :visible.sync="isShowAddType2Pop">
			<div v-if="isShowAddType2Pop">
				<div style="padding-top: 20px;">

					<el-form style="margin-left: 80px;" label-width="100px" size="small" ref="type2Form" :rules="type2FormRules" :model="type2Form">


						<el-form-item label="名称" prop="name">
							<el-input style="width: 250px;" v-model="type2Form.name" placeholder="请输入名称"></el-input>
						</el-form-item>

						
						<el-form-item label="图片" prop="image" style="position: relative;">
							<div style="position: absolute;top: 20px;left: -59px;font-size: 12px;color: #aaa;">120*120</div>
							<!-- :imgWidth="120" :imgHeight="120" fileAfterName=".png" -->
							<upload-img  :limit='1' accept="image/*"  ref="fengMianUpload" :imgList="type2Form.image"></upload-img>

						</el-form-item>

						<!-- <el-form-item label="排序" prop="sort">
							<el-input min="0" max="100" style="width: 250px;" type="number" v-model="type2Form.sort" placeholder="请输入0-100的排序值"></el-input>
						</el-form-item> -->


					</el-form>

				</div>
				<div class="dis" style="justify-content: flex-end;">

					<el-button round style="margin-left: 10px" @click="type2Save('type2Form')" type="primary" size="small">保存</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 添加或编辑二级分类弹窗end -->



		<!-- 添加分类弹窗start -->
		<el-dialog width="600px" custom-class="m-dialog" title="分类信息" center :visible.sync="isShowAddOrEditTypePop">
			<div v-if="isShowAddOrEditTypePop">
				<div style="padding-top: 20px;">

					<el-form style="margin-left: 80px;" label-width="100px" size="small" ref="form" :rules="formRules" :model="form">

						<el-form-item label="一级分类" prop="classes_id">
							<el-select v-model="form.classes_id" clearable placeholder="请选择" @change="type1SelectChange">

								<el-option v-for="item in type1" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>

							<el-button type="primary" @click="showAddType1Pop" style="border-radius: 0;margin-left: -5px;position: relative;border-top-right-radius: 4px;border-bottom-right-radius: 4px;z-index: 2;">添加</el-button>

						</el-form-item>

						<el-form-item label="二级分类" prop="category_id">
							<!--  :disabled="form.classes_id?false:true" -->
							<el-select v-model="form.category_id" clearable placeholder="请选择">

								<el-option v-for="item in type2" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>

							<!-- :disabled="form.classes_id?false:true" -->
							<el-button type="primary" @click="showAddType2Pop" style="border-radius: 0;margin-left: -5px;position: relative;border-top-right-radius: 4px;border-bottom-right-radius: 4px;z-index: 2;">添加</el-button>

						</el-form-item>





					</el-form>

				</div>
				<div class="dis" style="justify-content: flex-end;">

					<el-button round style="margin-left: 10px" @click="save('form')" type="primary" size="small">保存</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 添加分类弹窗end -->


		<!-- 全屏透明的loading加载start -->
		<div id="pageLoading" v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->
	</div>
</template>

<script>
	import {
		ajax
	} from '../../util/ajax.js';
	import uploadImg from '../../components/upload/uploadImg.vue'

	export default {
		name: 'brand',
		components: {
			uploadImg
		},
		data() {
			return {
				rowGoods_id: '', //列表项的商品id

				isShowCollapse: false, //是否展开搜索项
				searchForm: {


				},

				isShowAddOrEditTypePop: false, //是否显示添加或编辑弹窗
				isShowPreImg: false, //是否显示浏览图片的弹窗
				preImgUrl: '', //浏览图片弹窗的图片地址
				isShowType1EditGoods: false, //是否显示修改一级分类弹窗
				type1EditRow: '', //修改的一级分类对象

				tableHeight: 0, //表格高度
				loading: false,
				tableData: [], //表格数据
				pageNumber: 1, // 当前页数
				pageSize: 10, // 页面大小
				total: 0,



				type1: [], //一级分类
				type2: [], //二级分类
				type2SeletcArr: [], //根据选择的一级分类查找到的二级分类列表
				isShowAddType1Pop: false, //是否显示添加一级分类弹窗
				isShowAddType2Pop: false, //是否显示添加一级分类弹窗
				type1Form: {
					name: ''
				},
				type1FormRules: {

					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					ename: [{
						required: true,
						message: '请输入英文名称',
						trigger: 'blur'
					}],

					sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'blur'
					}]
				},


				type2Form: {
					category_id: '', //二级id
					image: [],
					name: '',
					sort: 0
				},
				type2FormRules: {

					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],

					image: [{
						type: 'array',
						required: true,
						message: '请选择上传图片',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'blur'
					}]
				},
				storeList:[],//门店列表
				form: {
					classes_id: '', //一级分类
					category_id: '', //二级分类
					status: 1, //状态 (0: 删除, 1: 添加)
				},
				formRules: {
					classes_id: [{
						required: true,
						message: '请选择一级分类',
						trigger: 'change'
					}],
					category_id: [{
						required: true,
						message: '请选择二级分类',
						trigger: 'change'
					}],
				},



				typeArr: [], //分类列表



			}
		},

		filters: {
			//把 2021-07-30 11:07:36 格式为 07-30
			//								11:07
			//使用格式 <p>{{row.stateChangeTime | timeBreak(0)}}</p>    <p>{{row.stateChangeTime | timeBreak(1)}}</p> 
			timeBreak(time, type, from) {
				if (time) {
					return type == 0 ? (from == undefined ? time.substr(5, 5) : time.substr(0, 10)) : (type == 2 ? time
					.substr(0, 10) : (from == undefined ? time.substr(11, 5) : time.substr(11, 8)));
				} else {
					return time;
				}
			},

			timeBreak2(val) {

				let v = val.split(' ')[0];

				return v;
			},

		},
		created() {
			this.getTableHeight();
		},
		mounted() {

			//挂载window.onresize事件(动态设置table高度)
			let _this = this;
			window.onresize = () => {
				if (_this.resizeFlag) {
					clearTimeout(_this.resizeFlag);
				}
				_this.resizeFlag = setTimeout(() => {
					_this.getTableHeight();
					_this.resizeFlag = null;
				}, 100);
			};

		
			this.initData();
		},

		methods: {
			
			//初始化表格数据
			async initData() {

				this.loading = true
				let res = await ajax('/getCategoryAll', 'GET'); //获取一级分类列表

				let list = res.data.data.list;
				
				this.loading = false;
				this.typeArr = list;
				let res2 = await ajax('/getCategory1', 'GET'); //获取一级分类列表
				let res3 = await ajax('/getCategory2', 'GET'); //获取二级分类列表
				this.type1 = res2.data.data.list;
				this.type2 = res3.data.data.list;
				
			
			},


			//计算table高度(动态设置table高度)
			getTableHeight() {
				let tableH = 150; //距离页面下方的高度,值越大表格高度越小
				let tableHeightDetil = window.innerHeight - tableH;
				if (tableHeightDetil <= 300) {
					this.tableHeight = 300;
				} else {
					this.tableHeight = window.innerHeight - tableH;
				}
			},

			//删除一级分类
			async type1Del(item) {

				this.$confirm('确定要删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.loading = true
					let ress = await ajax('/category1Del', 'GET', {
						id:item.id
					});
					this.loading = false;
					this.$message({
						message: '成功',
						type: 'success'
					});
					
					this.initData();
				});
				
			},
			
			//删除二级分类
			async type2Del(item) {
			
				this.$confirm('确定要删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.loading = true
					let ress = await ajax('/category2Del', 'GET', {
						id: item.id,
					
					});
					this.loading = false;
					this.$message({
						message: '成功',
						type: 'success'
					});
					
					this.initData();
				});
				
			},

			//弹出修改一级分类弹窗
			showType1EditGoods(row) {
				this.isShowAddType1Pop = true;

				this.type1Form.classes_id = row.id;
				this.type1Form.name = row.name;
				//this.type1Form.sort = row.sort;

			},
			//弹出修改二级分类弹窗
			showType2EditGoods(row) {
				this.isShowAddType2Pop = true;
				console.log('row=>', row)
				this.type2Form.category_id = row.id;
				this.type2Form.category1_id = row.category1id;
				this.type2Form.image = [{
					url: row.image
				}];
				this.type2Form.name = row.name;
				this.type2Form.sort = row.sort;

			},
			//添加一级分类弹窗
			showAddType1Pop() {
				this.isShowAddType1Pop = true;
			},
			//添加二级分类弹窗
			showAddType2Pop() {
				this.isShowAddType2Pop = true;
			},
			//添加分类的弹窗上一级类别的改变事件
			type1SelectChange(id) {
				// let arr = [];
				// this.typeArr.forEach((item)=>{

				// 	if(item.id==id){
				// 		let arr = item.children;
				// 	}
				// })

				// this.type2SeletcArr = arr;
				// console.log('this.type2SeletcArr',this.type2SeletcArr)
			},
			//点击编辑
			showEditBrand(row) {
				this.form.brand_id = row.id;
				this.form.name = row.name;
				this.form.image = [{
					url: row.image
				}];
				this.form.sort = row.sort;
				this.isShowAddOrEditTypePop = true;
			},
			//添加品牌弹窗
			showAddOrEditTypePop() {
				this.isShowAddOrEditTypePop = true;
			},

			//一级分类的保存
			async type1Save(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {

						let method = 'POST';
						// if (this.type1Form.classes_id) {
						// 	method = 'PUT';
						// }

						// if (this.type1Form.sort < 0 || this.type1Form.sort > 100) {
						// 	this.$message.error('请填写0-100的排序值');
						// 	return
						// }
						this.loading = true;
						
						let ress = await ajax('/addOrEditCategory1', method, {
							id: this.type1Form.classes_id?this.type1Form.classes_id:null,
							name: this.type1Form.name
						});
						this.loading = false;

						this.$refs[formName].resetFields();
						this.type1Form = {
							name: ''
						};

						this.$message({
							message: '成功',
							type: 'success'
						});
						this.isShowAddType1Pop = false;
						this.initData();
					} else {
						this.$message.error('请填写完整信息');
						return false;
					}
				});


			},



			//二级分类的保存
			async type2Save(formName) {
				this.form.image = this.$refs.fengMianUpload.getImgList(); //图片
				this.$refs[formName].validate(async (valid) => {
					if (valid) {

						this.loading = true;
						let ress = await ajax('/addOrEditCategory2', 'POST', {
							id: this.type2Form.category_id?this.type2Form.category_id:null,
							category1id:this.type2Form.category1_id,
							name: this.type2Form.name,
							img: this.type2Form.image[0].url
						});
						this.loading = false;

						this.$refs[formName].resetFields();
						this.type2Form = {
							category_id: '', //二级id
							image: [],
							name: ''
						};

						this.$message({
							message: '成功',
							type: 'success'
						});
						this.isShowAddType2Pop = false;
						this.initData();
					} else {
						this.$message.error('请填写完整信息');
						return false;
					}
				});


			},



			//添加分类的保存
			async save(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						//一级分类和二级分类关联
						let ress = await ajax('/categoryRelevancy', 'GET', {
							category1_id: this.form.classes_id,//一级分类id
							category2_id: this.form.category_id,//二级分类id

						});
						this.loading = false;

						this.$refs[formName].resetFields();
						this.form = {
							classes_id: '',
							category_id: '',
							status: 1

						};

						this.$message({
							message: '成功',
							type: 'success'
						});
						this.isShowAddOrEditTypePop = false;
						this.initData();
					} else {
						this.$message.error('请填写完整信息');
						return false;
					}
				});


			},
			//展开搜索项
			isShowCollapseF() {
				this.isShowCollapse = !this.isShowCollapse;
			},
			//翻页
			changePage(pageNumber) {
				console.log('即将翻到' + pageNumber + '页')
				this.pageNumber = pageNumber
				this.initData()
			},

			//点击头像
			handlePreView(url) {
				this.preImgUrl = url
				this.isShowPreImg = true
			},





			//搜索
			handleSearch() {
				this.pageNumber = 1;
				this.initData()
			},

			//重置
			handleReset() {
				this.$refs.searchForm.resetFields();
				this.searchForm = {};
				this.pageNumber = 1
				this.pageSize = 10
				this.tableData = []; //表格数据修改为空
				this.initData()
			},






		}


	}
</script>

<style scoped>
	.tableTem ul {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;

	}

	.tableTem ul li {
		list-style: none;

		width: 110px;
		min-height: 60px;
		border-left: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		margin-bottom: -1px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 3px;


	}

	.tableTem .content {

		padding-top: 3px;
		font-size: 14px;
		color: #2d8cf0;
		font-weight: 600;
	}

	.tableTem .avatar img {
		border-radius: 10px;
		width: 60px;
		height: 60px;
	}

	.tableTem .avatar {

		padding-right: 20px;
		display: flex;
		justify-content: center;

	}

	.tableTem .dian {
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100px;
	}

	.tableTem .dianL {
		text-align: left;
		font-size: 13px;
		color: #333;
	}


	.titleFont {
		font-size: 12px !important;
		flex-shrink: 0;
		line-height: 25px;
		color: rgb(136, 136, 136);
	}





	.sortLine {
		display: flex;
		align-items: center;
	}

	.sortItem {

		height: 100%;
		color: rgb(156, 156, 156);
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		min-width: 80px;
	}

	>>>#mTable th {
		text-align: center;
	}

	.sortItem:hover {
		background-color: #119744 !important;
		color: #fff !important;
	}

	.sortLine .sortItem:last-child {
		border-right: none;
	}

	>>>.el-table tbody tr:hover>td {
		background-color: #ffffff !important
	}


	>>>.el-icon-arrow-down {
		font-size: 12px;
	}

	>>>.demonstration {
		display: block;
		color: #8492a6;
		font-size: 14px;
		margin-bottom: 20px;
	}

	>>>.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	>>>.el-table td {
		padding: 10px 0 !important;
	}
</style>
